import React from "react";
import { Icon, Menu, Dropdown } from "antd";

const UserMenu = ({ name = null, isOperadorAdmin = false, onClick }) => {
  const menu = (
    <Menu onClick={onClick} className="dropdown-user-menu">
      {!!isOperadorAdmin && <Menu.Item key="user-menu-changeop">Trocar Operador</Menu.Item>}
      <Menu.Item key="user-menu-changepwd">Alterar minha senha</Menu.Item>
      <Menu.Item key="user-menu-logout">Sair / Logout</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <a
        className="ant-dropdown-link"
        href="#user-menu"
        title={`Menu do Usuário: ${name}`}
        onClick={event => event.preventDefault()}>
        <Icon type={isOperadorAdmin ? "lock" : "user"} />{" "}
        {!!name && name.length > 40 ? name.substr(0, 37) + "..." : name} <Icon type="down" />
      </a>
    </Dropdown>
  );
};

export default UserMenu;
