import React, { Component, Fragment } from "react";
import { Form, Alert, Button } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";
import { n, filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm } from "components/FullTable";
import classnames from "classnames";

const FormItem = Form.Item;

const decorateRows = (item, index) =>
  classnames("base-table--row", {
    "base-table--row__odd": index % 2 !== 0,
    "base-table--row__sucess": item.Acomp.resultado.indexOf("SUBINDO") != -1,    
    "base-table--row__danger": item.Acomp.resultado.indexOf("CAINDO") != -1,
    "base-table--row__invalid": item.Acomp.resultado.indexOf("MANTENDO") != -1,
    "base-table--row__disabled": item.Acomp.resultado.indexOf("CANAL NAO MOV") != -1,
  });

  const DescricaoCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

@observer
class NotasFiscaisScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Volume Perfil",
      uri: "/r/volume_perfil",
      uri: !isMop ? "/r/volume_perfil" : "/r/volume_perfil/mop",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <Fragment>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "normal" }}>
      <Alert
      style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginRight: 15 }}
      message={`Cor perfis "Caindo"! `}
      type="error"
      showIcon
      />
      <Alert
      style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15 }}
      message={`Cor Perfis "Mantendo"! `}
      type="warning"
      showIcon
      />
      <Alert
      style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginLeft: 15 }}
      message={`Cor perfis "Subindo"! `}
      type="success"
      showIcon
      />
      </div>

      </Fragment>

    );
  };
  render() {
    const { store } = this.props;
    const columns = [
      {
        title: "Código",
        dataIndex: "Acomp.cod_cliente",
        key: "Acomp.cod_cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cod_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cod_cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9%",
      },
      {
        title: "Loja",
        dataIndex: "Acomp.loja_cliente",
        width: "5%",
      },
      {
        title: "Cliente",
        dataIndex: "Acomp.nome_cliente",
        className: "text-left",
        key: "Acomp.nome_cliente",
        width: "15%",
        sorter: true,
        filteredValue: this.ds.filterValue("nome_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Acomp.nome_cliente", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Linha",
        dataIndex: "Acomp.linha_perfil",
        key: "Acomp.linha_perfil",
        width: "9%",
      },
      {
        title: "Perfil",
        dataIndex: "Acomp.perfil",
        key: "Acomp.perfil",
        width: "10%",
      },
      {
        title: "Forçado ?",
        dataIndex: "Acomp.perfil_forcado",
        key: "Acomp.perfil_forcado",
        width: "11%",
        sorter: true,
      },

      {
        title: "Volume total",
        dataIndex: "Acomp.quantidade_total",
        key: "Acomp.quantidade_total",
        className: "text-right",
        sorter: true,
        render: text => <WrappedValue value={n(+text,2)} type="text" />,
        width: "12.9%",
      },
      // {
      //   title: "Meta Atual",
      //   dataIndex: "Acomp.meta_perfil_atual",
      //   key: "Acomp.meta_perfil_atual",
      //   className: "text-right",
      //   sorter: true,
      //   render: text => <WrappedValue value={n(+text,2)} type="text" />,
      //   width: "12%",
      //   exportOnly: true,
      // },
      {
        title: "Falta p/manter",
        dataIndex: "Acomp.quantidade_faltante",
        key: "Acomp.quantidade_faltante",
        className: "text-right",
        sorter: true,
        render: text => <WrappedValue value={n(+text,2)} type="text" />,
        width: "13%",
      },
      // {
      //   title: "Meta Superior",
      //   dataIndex: "Acomp.meta_perfil_superior",
      //   key: "Acomp.meta_perfil_superior",
      //   className: "text-right",
      //   sorter: true,
      //   render: text => <WrappedValue value={n(+text,2)} type="text" />,
      //   width: "10%",
      //   exportOnly: true,
      // },
      {
        title: "falta p/subir",
        dataIndex: "Acomp.quantidade_superior",
        key: "Acomp.quantidade_superior",
        className: "text-right",
        sorter: true,
        render: text => <WrappedValue value={n(+text,2)} type="text" />,
        width: "11%",
      },
      {
        title: "Resultado",
        dataIndex: "Acomp.resultado",
        key: "Acomp.resultado",
        sorter: true,
        width: "20%",
      },


    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
          <div>
            {store.isOperador && (
              <Button
                title="Modo de listagem dos clientes do operador"
                type="primary"
                icon="filter"
                ghost={!!this.state.isMop}
                onClick={() =>
                  this.props.history.push(!this.state.isMop ? `/r/volume_perfil-mop` : `/r/volume_perfil`)
                }>
                {!this.state.isMop ? `Apenas meus clientes` : `Todos os clientes`}
              </Button>
            )}
          </div>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          rowClassName={decorateRows}
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.Acomp.cod_cliente}:${item.Acomp.loja_cliente}:${item.Acomp.linha_perfil}` }
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter seus clientes no momento"
        />
      </div>
    );
  }
}

export default withStore(NotasFiscaisScreen);
