import axios from "axios";
import { getApiUrl, fetchOptions, fetchPostOptions } from "@env";

const FetchCache = {
  data: {},
};

export const clearFetchCache = () => {
  FetchCache.data = {};
};

export const fetchApiWithCache = async (uri, ...args) => {
  if (uri in FetchCache.data) {
    return {
      data: JSON.parse(FetchCache.data[uri]),
    };
  }

  const { data, ...response } = await fetchApi(uri, ...args);
  if (!!data.success) {
    FetchCache.data[uri] = JSON.stringify(data);
  }

  return { data, ...response };
};

export const fetchApi = (uri, queryParams = {}, params = {}) => {
  return axios(
    fetchOptions({
      url: getApiUrl(uri, queryParams),
      ...params,
    })
  );
};

export const fetchPostApi = (uri, data, queryParams = {}, params = {}) => {
  return axios(
    fetchPostOptions(data, {
      url: getApiUrl(uri, queryParams),
      ...params,
    })
  );
};

export const formPostApi = (uri, data, multipart = false, params = {}, queryParams = {}) => {
  return axios(
    fetchPostOptions(
      data,
      {
        url: getApiUrl(uri, queryParams),
        ...params,
      },
      {
        "Content-Type": multipart ? "multipart/form-data" : "application/x-www-form-urlencoded; charset=utf-8",
        Accept: "*/*",
      }
    )
  );
};

export const fetchPing = (firstPing = false, location = null, ...args) =>
  fetchApi(`/ping`, !!firstPing ? { first: "true", location } : { location }, ...args);

export const fetchLogin = (login, senha, ...args) => fetchPostApi(`/auth`, { login, senha }, ...args);

export const fetchPasswordForgot = (login, ...args) => fetchPostApi(`/auth/password_forgot`, { login }, ...args);
