import React, { PureComponent } from "react";
import "./LoginLayout.less";

const LoginFooter = () => (
  <footer>
    <div className="footer-crop">
      <p>Copyright ©2021 Anjo Tintas. Todos os diretos reservados.</p>
      <a href="https://www.anjo.com.br" target="_blank" rel="noopener noreferrer">
        Desenvolvimento <strong>Anjo Tintas</strong>
      </a>
    </div>
  </footer>
);

export default class LoginLayout extends PureComponent {
  render() {
    return (
      <div className="app-login">
        <main>{this.props.children}</main>
        <LoginFooter />
      </div>
    );
  }
}
