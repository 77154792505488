import React, { Component, Fragment } from "react";
import { Form } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm } from "components/FullTable";

@observer
class AnaliseVolumeItemReport extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Análise de Volume",
      uri: "/r/analise-volume/"+props.nota,
      controlled: false,
    });
    
    if(props.data$0)
      this.ds.filters.data$0 = props.data$0;

    if(props.data$1)
      this.ds.filters.data$1 = props.data$1;

    this.ds.filters.nota = props.nota;
  }
  
  render() {
    var columns = [
      {
        title: "Produto",
        dataIndex: "AnaliseVolumeItem.produto",
        key: "AnaliseVolumeItem.produto",
        sorter: false,
        filteredValue: this.ds.filterValue("produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "produto", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Classe",
        dataIndex: "AnaliseVolumeItem.classe",
        key: "AnaliseVolumeItem.classe",
        sorter: false,
        filteredValue: this.ds.filterValue("classe"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Classe" {...props} />,
        filterParser: filter => ({ ...filter, field: "classe", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Faturamento",
        dataIndex: "AnaliseVolumeItem.faturamento",
        key: "AnaliseVolumeItem.faturamento",
        sorter: false,
        filteredValue: this.ds.filterValue("faturamento"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Faturamento" {...props} />,
        filterParser: filter => ({ ...filter, field: "faturamento", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Imposto",
        dataIndex: "AnaliseVolumeItem.imposto",
        key: "AnaliseVolumeItem.imposto",
        sorter: false,
        filteredValue: this.ds.filterValue("imposto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Imposto" {...props} />,
        filterParser: filter => ({ ...filter, field: "imposto", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Volume",
        dataIndex: "AnaliseVolumeItem.volume",
        key: "AnaliseVolumeItem.volume",
        sorter: false,
        filteredValue: this.ds.filterValue("volume"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Volume" {...props} />,
        filterParser: filter => ({ ...filter, field: "volume", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      }
    ];
    
    return (
      <div className="nfs-screen">
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.AnaliseVolumeItem.id}` }
          columns={columns}
          dataSource={this.ds}
          showClearFilters={false}
          errorMessage="Não foi possível obter suas análises no momento"
        />
      </div>
    );
  }
}

export default withStore(AnaliseVolumeItemReport);
