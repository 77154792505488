import styled from "styled-components";

const StatusCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => (!!props.danger ? "#e82a2c" : "#33af1a")};
  overflow: hidden;
  cursor: default;
  margin: 0 auto;
  padding: 0;
`;

export default StatusCircle;
