import { Menu } from "antd";
import styled from "styled-components";
import { darken } from "polished";

export const StyledMenu = styled(Menu)`
  &.ant-menu {
    height: calc(100vh - ${props => props.theme.headerHeight});
    border-right: none;

    .ant-menu-item,
    .ant-menu-submenu-title {
      border-bottom: 1px solid ${props => props.theme.borderColor};
      line-height: 46px !important;
      height: 46px !important;
      margin: 0 !important;

      &__featured {
        background-color: #fcc66a;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.14);
        transition: all 0.3s ease-in;

        &:hover {
          background-color: ${darken(0.2, "#fcc66a")};
        }
      }
      &__pro {
        background-color: #539ED6;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.14);
        transition: all 0.3s ease-in;
        
        &:hover {
          background-color: ${darken(0.2, "#69aadb")};
        }
      }
    }

    .ant-menu-submenu > .ant-menu {
      background-color: #f5f5f5;
    }
  }
`;

export const MenuWrapper = styled.nav`
  border-right: 1px solid ${props => props.theme.borderColor};
  overflow-x: hidden;
  overflow-y: auto;
`;
