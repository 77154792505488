import React, { Component, Fragment, useState } from "react";
import { Col, Form, Row, Spin, message } from "antd";
import { observer } from "mobx-react";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, SearchForm, Toolbar } from "components/FullTable";
import styled from "styled-components";
import { fetchApi } from "actions";

const FormItem = Form.Item;

const ContainerMonths = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;  // Keep items in a single line
`;

const CardMonth = styled.div`
  background-color: #0f73b9;
  width: 80px;
  min-width: 80px;
  height: 40px;
  border-radius: 6px;
  margin: 4px;
  display: flex;
  flex-direction: column;  // Alterado para column
  justify-content: center;
  align-items: center;
`;

const RenderCellExpand = ({ promotores }) => {
  const [loading, setLoading] = useState(true);
  const [listOcisiodade, setListOcisiodade] = useState([]);

  React.useEffect(() => {
    const agruparPorAno = (dados) => {
      const dadosAgrupados = {};
      dados.forEach(item => {
        const ano = item.ano;
        if (!dadosAgrupados[ano]) {
          dadosAgrupados[ano] = [];
        }
        dadosAgrupados[ano].push(item);
      });
      return Object.entries(dadosAgrupados).map(([ano, data]) => ({ title: `Ano ${ano}`, data }));
    };

    const fetchData = async () => {
      try {
        const response = await fetchApi(`/r/ociosidade/${promotores.a3_cod}`);
        const { data } = response;
        const groupedData = agruparPorAno(data.data);
        setListOcisiodade(groupedData);
      } catch (error) {
        message.error('Erro ao buscar informações ao promotor selecionado');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [promotores.a3_cod]);

  return (
    <Col span={24}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <Spin tip="Carregando..." spinning={loading} />
        </div>
      ) : (
        listOcisiodade.map(item => (
          <div key={item.title}>
            <h4><strong>{item.title}</strong></h4>
            <ContainerMonths>
              {item.data.map(monthItem => (
                <CardMonth key={`${monthItem.ano}-${monthItem.mes}`}>
                  <Col><strong style={{ color: '#fff' }}>{monthItem.mes} - {monthItem.ano}</strong></Col>
                  <Col><strong style={{ color: '#fff' }}>{monthItem.semagenda} dia(s)</strong></Col>
                </CardMonth>
              ))}
            </ContainerMonths>
          </div>
        ))
      )}
    </Col>
  );
};

@observer
class OciosidadeReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Ociosidade",
      uri: "/r/ociosidade",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }

  render() {
    const columns = [
      {
        title: <span className="th-small-label">Código</span>,
        dataIndex: "Promotores.a3_cod",
        key: "Promotores.a3_cod",
        sorter: false,
        filteredValue: this.ds.filterValue("a3_cod"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código" {...props} />,
        filterParser: filter => ({ ...filter, field: "a3_cod", value: `%${filter.value}`, op: "LIKE" }),
        width: "5%",
      },
      {
        title: <span className="th-small-label">Promotor</span>,
        dataIndex: "Promotores.a3_nome",
        key: "a3_nomees.a3_nome",
        sorter: false,
        filteredValue: this.ds.filterValue("a3_nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "a3_nome", value: `%${filter.value}`, op: "LIKE" }),
        width: "94%",
      }
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          loading={this.props.store.isLoading}
          rowKey={item => `${item.Promotores.a3_cod}`}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas ociosidades no momento"
          expandedRowRender={({ Promotores }) => !!Promotores && Promotores && <RenderCellExpand promotores={Promotores} />}
        />
      </div>
    );
  }
}

export default withStore(OciosidadeReportScreen);
