import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker, Modal } from "antd";
import { observer } from "mobx-react";
import moment from "moment";

import { filterDate, getData } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { WrappedValue, TableDataSource, TextFilter, SearchForm } from "components/FullTable";
import { fetchBolMail } from "actions/relatorios";

const FormItem = Form.Item;
const NOW = Date.now();

@observer
class BoletosScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Boletos em Aberto",
      uri: !!props.match.params && !!props.match.params.cliente ? "/boletos/" + props.match.params.cliente : "/boletos",
      state: {
        uri_boleto_422: null,
        uri_boleto_341: null,
        uri_boleto_001: null,
      },
    });

    this.state = {};
  }
  handleBoletoClick = item => {
    if (!!item.Boleto.can_print) {
      window.open(
        "link_boleto" in item.Boleto && !!item.Boleto.link_boleto
          ? item.Boleto.link_boleto
          : `${
              +item.Boleto.banco === 422
                ? this.ds.state.uri_boleto_422
                : +item.Boleto.banco === 341
                ? this.ds.state.uri_boleto_341
                : this.ds.state.uri_boleto_001
            }${item.Boleto.hash_boleto}`
      );
      return;
    }

    Modal.warn({
      title: "Atenção",
      content: item.Boleto.warn || "Não foi possível imprimir este boleto no momento",
    });
  };

  handleEmail = item => {
    if (!!item.Boleto.can_print) {
      this.fetchBolMail(item.Boleto.hash_boleto);
      return;
    }

    Modal.warn({
      title: "Atenção",
      content: item.Boleto.warn || "Boleto vencido ou em protesto",
    });
  };

  async fetchBolMail(hash) {
    const { history, store } = this.props;
    store.isLoading = true;
    try {
      const response = await fetchBolMail(hash);
      
      if (!!response.success) {
        const { options } = response.data;
        Modal.success({
          title: "E-mail enviado com sucesso!",
          content: "O e-mail com o link para emissão do boleto será enviada para o cliente/representante dentro de alguns minutos.",
          keyboard: false,
        });

      } else {
        throw new Error("Não foi possível enviar e-mail da nota neste momento");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;
      const errorMessages = {
        1: "Não foi possível enviar e-mail da nota neste momento",
        10: "E-mail não encontrado no sistema ou indisponível no momento",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || errorMessages[1],
      });
    } finally {
      store.isLoading = false;
    }
  }

  rowClassNameWarning = (record, index) => {
    const classes = [`full-table--row`];
    if (!!record.Boleto.data_vencimento && moment(record.Boleto.data_vencimento).diff(NOW, "days") < 0) {
      classes.push(`full-table--row__danger`);
    } else if (index % 2 !== 0) {
      classes.push(`full-table--row__odd`);
    }
    return classes.join(" ");
  };
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          data_vencimento$0: "date",
          data_vencimento$1: "date",
        }}
        filterParser={{
          data_vencimento$0: filter => ({
            ...filter,
            field: "Boleto.data_vencimento",
            value: filterDate(filter.value),
            type: "str_date",
            op: ">=",
          }),
          data_vencimento$1: filter => ({
            ...filter,
            field: "Boleto.data_vencimento",
            value: filterDate(filter.value),
            type: "str_date",
            op: "<=",
          }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Filtrar por Vencimento">
              {form.getFieldDecorator("data_vencimento$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("data_vencimento$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const columns = [
      {
        title: "Emissão",
        dataIndex: "Boleto.data_emissao",
        dataType: "date",
        key: "data_emissao",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("data_emissao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Emissão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Boleto.data_emissao",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Nº Bancário",
        dataIndex: "Boleto.numero_bancario",
        key: "numero_bancario",
        sorter: true,
        width: "13.5%",
        filteredValue: this.ds.filterValue("numero_bancario"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nº Bancário" {...props} />,
        filterParser: filter => ({ ...filter, field: "Boleto.numero_bancario", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Nº Título",
        dataIndex: "Boleto.numero_titulo",
        key: "numero_titulo",
        sorter: true,
        width: "11%",
        filteredValue: this.ds.filterValue("numero_titulo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nº Título" {...props} />,
        filterParser: filter => ({ ...filter, field: "Boleto.numero_titulo", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Vecto",
        dataIndex: "Boleto.data_vencimento",
        dataType: "date",
        key: "data_vencimento",
        sorter: true,
        width: "9%",
        filteredValue: this.ds.filterValue("data_vencimento"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Vecto (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Boleto.data_vencimento",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Código",
        dataIndex: "Boleto.codigo_cliente",
        key: "codigo_cliente",
        sorter: false,
        render: (text, item) => (
          <WrappedValue value={`${item.Boleto.codigo_cliente} / ${item.Boleto.loja_cliente}`} type="text" />
        ),
        filteredValue: this.ds.filterValue("codigo_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Cliente" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Boleto.codigo_cliente",
          value: `%${filter.value}`,
          op: "LIKE",
        }),
        width: "9.25%",
      },
      {
        title: "Loja",
        dataIndex: "Boleto.loja_cliente",
        exportOnly: true,
      },
      {
        title: "Cliente",
        dataIndex: "Cliente.razao_social",
        key: "razao_social",
        className: "text-left",
        sorter: true,
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "boleto.razao_social", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Valor",
        dataIndex: "Boleto.valor_total",
        dataType: "money",
        key: "valor_total",
        sorter: true,
        width: "10%",
      },
      !this.props.store.isB2B
        ? {
          title: "Ações",
          key: "actions",
          width: 130,
          render: (text, record) => (
            <div className="actions">
              <Button icon="printer" onClick={() => this.handleBoletoClick(record)} size="small" type="primary">
                Imprimir
              </Button>
            </div>
          ),
        }
        : {
          title: "Ações",
          key: "actions",
          width: 190,
          render: (text, record) => (
            <div className="actions">
              <Button
                icon="mail"
                size="small"
                onClick={event => {
                  event.preventDefault();
                  this.handleEmail(record);
                }}
                type="primary">
                E-mail
              </Button>
              <Button icon="printer" onClick={() => this.handleBoletoClick(record)} size="small" type="primary">
                Imprimir
              </Button>
            </div>
          ),
        },
    ];

    const hasClienteFiltro = !!this.props.match.params && !!this.props.match.params.cliente;

    return (
      <div className="boletos-screen">
        <PageTitle title={this.ds.name}>
          {!this.ds.isLoading && hasClienteFiltro && this.ds.items.length > 0 && (
            <strong style={{ letterSpacing: -0.5 }}>{this.ds.items[0].Cliente.razao_social}</strong>
          )}
        </PageTitle>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          rowKey={item => `${item.Boleto.numero_titulo}:${item.Boleto.parcela}:${item.Boleto.codigo_cliente}`}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          rowClassName={this.rowClassNameWarning}
          errorMessage="Não foi possível obter seus boletos em aberto no momento"
        />
      </div>
    );
  }
}

export default withStore(BoletosScreen);
