/**
 * @flow
 */

import { observable, computed } from "mobx";
import _size from "lodash/size";
import _isObject from "lodash/isObject";

import exportable from "stores/ExportableStore";

export default class TableStaticDataSource {
  @observable items: Array = [];
  @observable.shallow selectedRowKeys: Array = [];
  @observable.shallow state: Object = {};
  @observable pagination: Object = {};
  @observable filters: Object = {};
  @observable filterParsers: Object = {};
  @observable sorter: Object = {};
  @observable name: string = null;
  @observable controlled: boolean = false;

  hasPagination: boolean = false;

  constructor(items: Array = [], state: Object = null, filterParsers: Object = null, controlled: boolean = false) {
    if (Array.isArray(items)) {
      this.items = items;
      if (!!state) this.state = state;
      if (!!filterParsers) this.filterParsers = filterParsers;
      this.controlled = controlled;
    } else if (_isObject(items)) {
      Object.keys(items).forEach(prop => {
        this[prop] = items[prop];
      });
    }
  }

  @computed
  get hasSorter() {
    return !!this.sorter && "field" in this.sorter;
  }

  @computed
  get hasFilter() {
    return !!this.filters && _size(this.filters) > 0;
  }

  export(...args) {
    exportable.export(...args);
  }

  filterValue(key, defaultValue = null) {
    if (key in this.filters && !!this.filters[key]) {
      return Array.isArray(this.filters[key]) ? this.filters[key][0] : this.filters[key];
    }

    return defaultValue;
  }

  reset() {
    this.items = [];
    this.pagination = {};
    this.filters = {};
    this.sorter = {};
  }

  async fetch() {
    return;
  }
}
