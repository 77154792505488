import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm } from "components/FullTable";

const FormItem = Form.Item;

@observer
class MaquinasReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Máquinas",
      uri: "/r/maquinas",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          data$0: "date",
          data$1: "date",
        }}
        filterParser={{
          data$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          data$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Filtrar por Data de Resposta">
              {form.getFieldDecorator("data$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("data$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const columns = [
      {
        title: "Código Produto",
        dataIndex: "Maquina.produto",
        key: "Maquina.produto",
        sorter: false,
        filteredValue: this.ds.filterValue("produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "produto", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Número de Série",
        dataIndex: "Maquina.nserie",
        key: "Maquina.nserie",
        sorter: false,
        filteredValue: this.ds.filterValue("nserie"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Número de Série" {...props} />,
        filterParser: filter => ({ ...filter, field: "nserie", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Descrição",
        dataIndex: "Maquina.descricao",
        key: "Maquina.descricao",
        sorter: false,
        filteredValue: this.ds.filterValue("descricao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Descrição" {...props} />,
        filterParser: filter => ({ ...filter, field: "descricao", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Código do Cliente",
        dataIndex: "Maquina.codcliente",
        key: "Maquina.codcliente",
        sorter: false,
        filteredValue: this.ds.filterValue("codcliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código do Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "codcliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Nome do Cliente",
        dataIndex: "Maquina.nomecliente",
        key: "Maquina.nomecliente",
        sorter: false,
        filteredValue: this.ds.filterValue("nomecliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome do Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "nomecliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Nome do Gerente",
        dataIndex: "Maquina.gerente",
        key: "Maquina.gerente",
        sorter: false,
        filteredValue: this.ds.filterValue("gerente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome do Gerente" {...props} />,
        filterParser: filter => ({ ...filter, field: "gerente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "COD. Representante",
        dataIndex: "Maquina.codvendedor",
        key: "Maquina.codvendedor",
        sorter: false,
        filteredValue: this.ds.filterValue("codvendedor"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por COD. Representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "codvendedor", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Representante",
        dataIndex: "Maquina.nomevenderor",
        key: "Maquina.nomevenderor",
        sorter: false,
        filteredValue: this.ds.filterValue("nomevenderor"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "nomevenderor", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Pedido",
        dataIndex: "Maquina.pedido",
        key: "Maquina.pedido",
        sorter: false,
        filteredValue: this.ds.filterValue("pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Pedido" {...props} />,
        filterParser: filter => ({ ...filter, field: "pedido", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Data Faturamento",
        dataIndex: "Maquina.datamovimentacao",
        dataType: "date",
        key: "Maquina.datamovimentacao",
        sorter: true,
        width: "9.08%",
        filteredValue: this.ds.filterValue("datamovimentacao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data da Faturamento (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Maquina.datamovimentacao",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Modalidade",
        dataIndex: "Maquina.atividade",
        key: "Maquina.atividade",
        sorter: false,
        filteredValue: this.ds.filterValue("atividade"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Modalidade" {...props} />,
        filterParser: filter => ({ ...filter, field: "atividade", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.Maquina.id}` }
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas maquinas no momento"
        />
      </div>
    );
  }
}

export default withStore(MaquinasReportScreen);
