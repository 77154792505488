import React, { PureComponent } from "react";
import { Layout, Icon } from "antd";
import styled from "styled-components";
import { darken } from "polished";

import { isDev, isStaging } from "@env";

const ToggleMenuWrapper = styled.div`
  line-height: 44px;
  color: #fff;
  cursor: pointer;

  & > .anticon {
    font-size: 21px;
    line-height: 44px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s;
  }

  &:hover {
    color: ${darken(0.1, "#fff")};
  }
`;

const TriggerLabel = styled.span`
  display: inline-block;
  font-size: 21px;
  font-weight: 600;
  line-height: 44px;
  margin-left: 9px;
  transition: color 0.3s;
`;

const HeaderWrapper = styled(Layout.Header)`
  &.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.primary};
    padding: 0 28px;
    min-height: ${props => props.theme.headerHeight};
    line-height: 1;

    .ant-dropdown-link {
      font-size: 15px;
      color: #fff;

      &:focus {
        text-decoration: none;
      }
    }
  }
`;

const LogoWrapper = styled.h1`
  margin: 0;
  cursor: default;
`;

const ToggleMenuButton = ({ collapsed = false, onClick }) => (
  <ToggleMenuWrapper onClick={onClick}>
    <Icon type={collapsed ? "menu-unfold" : "menu-fold"} />
    <TriggerLabel>Menu</TriggerLabel>
  </ToggleMenuWrapper>
);

class Header extends PureComponent {
  render() {
    const { collapsed = false, hideMenuButton = false, onCollapse, children } = this.props;

    return (
      <HeaderWrapper>
        {!hideMenuButton && <ToggleMenuButton collapsed={collapsed} onClick={onCollapse} />}
        <LogoWrapper>
          <img
            src={
              isStaging
                ? require("img/logoNexxus_staging.png")
                : isDev
                ? require("img/logoNexxus_dev.png")
                : require("img/logoNexxus.png")
            }
            alt="ANJO NEXXUS"
          />
        </LogoWrapper>
        {children}
      </HeaderWrapper>
    );
  }
}

export default Header;
