import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding-bottom: 9px;
  margin-bottom: 24px;
  cursor: default;

  .ant-form {
    margin-bottom: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    line-height: 16px;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 500;
  margin: 0;
  color: #323232;
  letter-spacing: 0.2px;
`;

const PageTitle = ({ title, children, meta = true, ...props }) => (
  <TitleWrapper {...props}>
    {!!meta && typeof title === "string" && (
      <Helmet>
        <title>{`${title} - ANJO NEXXUS`}</title>
      </Helmet>
    )}
    <Title>{title}</Title>
    {children}
  </TitleWrapper>
);

export default React.memo(PageTitle);
