import React, { Component, Fragment, useEffect, useState } from "react";
import { Alert, Form, Input, DatePicker, Button, Dropdown, Menu, Icon, Modal, Spin } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components";
import { CancelToken } from "axios";
import { n, filterDate, getData } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import AnexoPedido, { Item } from "components/pedidos/AnexoPedido";
import StatusCircle from "components/pedidos/StatusCircle";
import { default as StyledProdutoTag } from "components/pedidos/ProdutoTag";
import FullTable, { SearchForm, Toolbar, TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import { fetchVoltaPedido } from "actions/relatorios";
import './PedidosPeriodo.css';

const FormItem = Form.Item;

const PedidoTag = styled(StyledProdutoTag)`
  &.ant-tag {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.3px;
    padding: 1px 3px;
    margin: 3px 3px 1px 0;
    height: auto;
    cursor: default;
  }
`;

const AnexoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  
  .full-table--more-info {
    min-width: 300px;
    max-width: auto;
  }

  .ant-btn-sm {
    padding: 2px 7px;
    height: 28px;
  }

  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 5px;
  }

  .ant-spin-nested-loading {
    margin-left: 3px;
    margin-bottom: 3px;
    > div > .ant-spin {
      min-width: 300px;
      min-height: 190px !important;
    }
  }
`;

const PedidoExtra = observer(({ item }) => {
  const tags = [];
  if (!!item.Cliente.cidade) {
    tags.push({
      key: `cidade`,
      color: "#AC1A22",
      title: `Cidade / UF: ${item.Cliente.cidade} / ${item.Cliente.uf}`,
      children: `${item.Cliente.cidade} / ${item.Cliente.uf}`,
    });
  }
  if (!!item.Pedido.tipo_pedido) {
    tags.push({
      key: `tipo_pedido`,
      color: "#09609c",
      title: `Tipo de Pedido: ${item.Pedido.tipo_pedido}`,
      children: item.Pedido.tipo_pedido,
    });
  }
  if (!!item.Condicao.descricao_condicao) {
    tags.push({
      key: `descricao_condicao`,
      color: "#666",
      title: `Condição de Pagto: ${item.Condicao.descricao_condicao}`,
      children: `${item.Condicao.descricao_condicao}`,
    });
  }
  if (!!item.Pedido.prazo_entrega) {
    tags.push({
      key: `prazo_entrega`,
      color: "#09609c",
      title: `Previsão em Dias Úteis: ${item.Pedido.prazo_entrega}`,
      children: `Prev. ${item.Pedido.prazo_entrega} dias`,
    });
  }
  if (!!item.Pedido.peso_bruto) {
    tags.push({
      key: `peso_bruto`,
      color: "#d3a832",
      title: `Peso Bruto: ${n(item.Pedido.peso_bruto)}`,
      children: `Bruto ${n(item.Pedido.peso_bruto)}kg`,
    });
  }
  if (!!item.Pedido.peso_liquido) {
    tags.push({
      key: `peso_liquido`,
      color: "#d3a832",
      title: `Peso Líquido: ${n(item.Pedido.peso_liquido)}`,
      children: `Líq. ${n(item.Pedido.peso_liquido)}kg`,
    });
  }
  if (!!item.Pedido.total_nota) {
    tags.push({
      key: `total_nota`,
      color: "#33af1a",
      title: `Valor Total do Pedido: R$ ${n(item.Pedido.total_nota)}`,
      children: `R$ ${n(item.Pedido.total_nota)}`,
    });
  }
  if (!!item.Pedido.volume) {
    tags.push({
      key: `volume`,
      color: "#09609c",
      title: `Volume: ${n(item.Pedido.volume)}`,
      children: `Vol ${n(item.Pedido.volume)}`,
    });
  }
  if (!!item.Pedido.cashback) {
    tags.push({
      key: `cashback`,
      color: "#09609c",
      title: `Cashback: ${n(item.Pedido.cashback)}`,
      children: `R$ ${n(item.Pedido.cashback)}`,
    });
    
  }
  if (tags.length > 0) {
    return (
      <div className="produto-extra-tags pedido-extra-tags">
        {tags.map(tag => !!tag.children && 
        <PedidoTag {...tag} >
          {tag.key === "cashback" && (
          <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />
          )}
          {tag.children}
        </ PedidoTag>)}
      </div>
    );
  }

  return null;
});

function ExpendedAnexos({
  anexos,
  expanded = false,
  isLoading,
}) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let unmounted = false;
    let source = CancelToken.source();

    if (!!expanded) {
      setLoading(true);
    }

    return () => {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
      setLoading(false);
    };
  }, [anexos, expanded]);

  return (
    <AnexoContainer>
      <AnexoPedido
        className="full-table--more-info"
        anexos={anexos}
        isLoading={isLoading}>
      </AnexoPedido>
    </AnexoContainer>
  );
}

@observer
class PedidosPeriodoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Pedidos por Período",
      uri: "/r/pedidos",
    });

    this.state = {anexos: []};
  }
  
  clickActions(pedido, event = {}) {
    const { store } = this.props;
    const { key = "menu-print" } = event;
    switch (key) {
      case "menu-print":
        store.requestPrintForm(`/pedidos/pedido/${pedido.codigo_hash}`);
        break;
      case "menu-print-sdesc":
        store.requestPrintForm(`/pedidos/pedido/${pedido.codigo_hash_sdesc}`);
        break;
      case "menu-volta-pedido":
        this.fetchVoltaPedido(pedido.codigo_filial + '-' + pedido.numero_pedido);
        break;  
    }
  };

  async fetchVoltaPedido(slug) {
    const { history, store } = this.props;
    store.isLoading = true;
    try {
      const response = await fetchVoltaPedido(slug);
      
      if (!!response.success) {
        const { options } = response.data;
        this.ds.fetch();
        Modal.success({
          title: "Solicitação enviada com sucesso!",
          content: "O seu pedido será retornado a lista de orçamentos dentro de alguns minutos.",
          keyboard: false,
        });

      } else {
        throw new Error("Não foi possível solicitar o retorno deste pedido para a lista de orçamentos !");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;
      const errorMessages = {
        1: "Não foi possível solicitar o retorno deste pedido para a lista de orçamentos !",
        10: "Pedido não encontrado no sistema ou indisponível no momento",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || errorMessages[1],
      });
    } finally {
      store.isLoading = false;
    }
  }
  
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const { store } = this.props;

    const isNotCliente = store.isVendedorOrOperador || store.isDiretor;

    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={
          isNotCliente
            ? {
                data_emissao$0: "date",
                data_emissao$1: "date",
                razao_social: "text",
              }
            : {
                data_emissao$0: "date",
                data_emissao$1: "date",
              }
        }
        filterParser={{
          data_emissao$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          data_emissao$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
          razao_social: filter => ({
            ...filter,
            field: "Cliente.a1_nome",
            value: `%${filter.value}%`,
            op: "LIKE",
          }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Emissão do Pedido">
              {form.getFieldDecorator("data_emissao$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("data_emissao$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            {isNotCliente && (
              <FormItem label="Cliente">
                {form.getFieldDecorator("razao_social")(
                  <Input placeholder="Filtrar por Cliente" {...inputProps} style={{ width: 230 }} />
                )}
              </FormItem>
            )}
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
            <FormItem style={{ float: "right", margin: 0 }}>
              <Toolbar.Button
                  disabled={dataSource.isLoading}
                  size="default"
                  icon="reload"
                  ghost={true}
                  onClick={() => dataSource.fetch()}>
                  Atualizar
              </Toolbar.Button>
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };

  renderActionMenu = (text, item) => {
    const pedido = toJS(item.Pedido);
    const dontcanBack = pedido.tipo_pedido !== "PEDIDO" || pedido.ordem_impressa === "SIM" || pedido.posicao_pedido === 'solicitado retorno p/orçamento' ;

    const menu = (
      <Menu onClick={event => this.clickActions(pedido, event)}>
        <Menu.Item key="menu-print">
          <Icon type="printer" />
          Com desconto
        </Menu.Item>
        <Menu.Item key="menu-print-sdesc">
          <Icon type="printer" />
          Sem desconto
        </Menu.Item>
        <Menu.Item key="menu-volta-pedido" disabled={dontcanBack}>
          <Icon type="file" />
          Devolver para orçamento
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="actions">
        <Dropdown.Button
          disabled={false}
          onClick={this.clickActions.bind(this, pedido)}
          title="Imprimir pedido"
          size="small"
          key="menu-print"
          type="primary"
          overlay={menu}>
          <Icon type="printer" style={{ color: "#CCC" }} />
          Imprimir
        </Dropdown.Button>        
      </div>
    );
  };

  render() {
    const { store } = this.props;
    const{ anexos } = this.state;

    let columns = [
      {
        title: "Data",
        dataIndex: "Pedido.data_emissao",
        dataType: "date",
        key: "data_emissao",
        sorter: true,
        width: "7.5%",
        filteredValue: this.ds.filterValue("data_emissao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Emissão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({ ...filter, type: "date", value: filterDate(filter.value,"") }),
      },
      {
        title: "Número",
        dataIndex: "Pedido.numero_pedido",
        key: "numero_pedido",
        sorter: true,
        render: (text, item) => (
          <WrappedValue value={`${item.Pedido.numero_pedido} / ${item.Pedido.codigo_filial}`} type="text" />
        ),
        width: "9%",
        filteredValue: this.ds.filterValue("numero_pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Número" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.numero_pedido", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Filial",
        dataIndex: "Pedido.codigo_filial",
        exportOnly: true,
      },
      {
        title: "Código",
        dataIndex: "Pedido.codigo_cliente",
        key: "codigo_cliente",
        sorter: false,
        render: (text, item) => (
          <WrappedValue value={`${item.Pedido.codigo_cliente} / ${item.Pedido.loja_cliente}`} type="text" />
        ),
        width: "7%",
        filteredValue: this.ds.filterValue("codigo_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.codigo_cliente", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Loja",
        dataIndex: "Pedido.loja_cliente",
        exportOnly: true,
      },
      {
        title: "Cliente",
        dataIndex: "Cliente.razao_social",
        key: "razao_social",
        sorter: true,
        width: "20%",
        render: (text, item) => (
          <Fragment>
            <WrappedValue
              value={
                <Fragment>
                  {text}
                  {store.isTipoVendaInterna && !!store.vendedoresRelacionados[item.Pedido.codigo_vendedor] && (
                    <div>
                      <strong>Vendedor:</strong> {store.vendedoresRelacionados[item.Pedido.codigo_vendedor]}
                    </div>
                  )}
                </Fragment>
              }
              type="text"
            />
            <PedidoExtra item={item} />
          </Fragment>
        ),
        className: "text-left",
        filteredValue: this.ds.filterValue("a1_nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Cliente.a1_nome", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Cidade",
        dataIndex: "Cliente.cidade",
        exportOnly: true,
      },
      {
        title: "UF",
        dataIndex: "Cliente.uf",
        exportOnly: true,
      },
      {
        title: "Condição",
        dataIndex: "Condicao.descricao_condicao",
        exportOnly: true,
      },
      {
        title: "Program.",
        dataIndex: "Pedido.data_programado",
        dataType: "date",
        key: "data_programado",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("data_programado"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Programado (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({ ...filter, type: "date", value: filterDate(filter.value,"") }),
      },
      {
        title: "NF",
        dataIndex: "Pedido.nota_fiscal",
        key: "nota_fiscal",
        sorter: true,
        width: "9.35%",
        filteredValue: this.ds.filterValue("nota_fiscal"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por NF" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.nota_fiscal", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Posição",
        dataIndex: "Pedido.posicao_pedido",
        key: "posicao_pedido",
        sorter: true,
        className: "text-left",
        width: "11.5%",
        
        filteredValue: this.ds.filterValue("posicao_pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Posição" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Previsão (Dias Úteis)",
        dataIndex: "Pedido.prazo_entrega",
        exportOnly: true,
      },
      {
        title: "Peso Líquido",
        dataIndex: "Pedido.peso_liquido",
        exportOnly: true,
      },
      {
        title: "Peso Bruto",
        dataIndex: "Pedido.peso_bruto",
        exportOnly: true,
      },
      {
        title: "Valor Total",
        dataIndex: "Pedido.total_nota",
        exportOnly: true,
      },
      {
        title: "Volume",
        dataIndex: "Pedido.volume",
        exportOnly: true,
      },
      {
        title: "O.C.",
        dataIndex: "Pedido.ordem_compra",
        key: "ordem_compra",
        sorter: true,
        width: "8%",
        filteredValue: this.ds.filterValue("ordem_compra"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Ordem de Compra" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Status",
        dataIndex: "Pedido.status_pedido",
        key: "status_pedido",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("status_pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Status" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Ações",
        key: "actions",
        width: 137,
        render: this.renderActionMenu,
      },
    ];

    if (this.props.store.isVendedorOrOperador) {
      columns = [
        {
          title: null,
          key: "term",
          sorter: false,
          render: (_, item) => (
            <StatusCircle danger={!!item.Pedido.analise_pedido && item.Pedido.analise_pedido !== "NAO"} />
          ),
          className: "text-center pedido-status-column",
          width: "28px",
          export: false,
        },
        ...columns,
      ];
    }

    if (this.props.store.isDiretor) {
      columns = [
        ...columns,
        {
          title: "Vendedor",
          dataIndex: "Pedido.nome_vendedor",
          key: "nome_vendedor",
          sorter: true,
          className: "text-left",
          width: "12%",
          filteredValue: this.ds.filterValue("nome_vendedor"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Vendedor" {...props} />,
          filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
        },
      ];
    }
    
    return (
      <div className="pedidos-periodo-screen">
        <PageTitle title={this.ds.name} />
        {!this.ds.hasFilter && (
          <Alert
            message={
              <Fragment>
                Listagem inicial de pedidos no período de <em>90 dias</em>. Para buscas mais amplas, favor utilize os
                filtros de emissão abaixo.
              </Fragment>
            }
            type="info"
            showIcon
            style={{ margin: "-5px 0 16px" }}
          />
        )}
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          actionBar={<Toolbar exportable={true} printable={false} />}
          rowKey={item => `${item.Pedido.numero_pedido}:${item.Pedido.codigo_filial}`}
          loading={store.isLoading}
          columns={columns}
          dataSource={this.ds}          
          expandedRowRender={(item, index, indent, expanded) => (
            <ExpendedAnexos
              expanded={expanded}
              anexos={toJS(item.Pedido.anexos)}
              isLoading={this.props.store.isLoading}
            />
          )}
          rowClassName={(record) => { 
            if (!record.Pedido.anexos) { 
              return "NotExpandible";
            }
          }}
          errorMessage="Não foi possível obter seus pedidos no momento"
        />
      </div>
    );
  }
}

export default withStore(PedidosPeriodoScreen);
