import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import classnames from "classnames";

import { MenuWrapper, StyledMenu } from "components/Menu";
import ExternalLink from "components/ExternalLink";

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class MenuPromotor extends PureComponent {
  render() {
    const { collapsed = false, selected = [], isB2B, resetTabs } = this.props;
    const submenuKeys = [];

    const PROMOTOR_RELATORIOS_LINK = [
      { key: "saldo", href: "/r/saldo", label: "Saldo em Estoque" },
      { key: "clientes", href: "/r/clientes", label: "Relação de Clientes" },
    ]

    return (
      <MenuWrapper className={classnames("sidebar-nav", "sidebar-nav__cliente")}>
        <StyledMenu
          mode="inline"
          selectable={false}
          defaultSelectedKeys={selected}
          defaultOpenKeys={!collapsed ? submenuKeys : []}
          inlineCollapsed={collapsed}
          inlineIndent={16}>
          <MenuItem key="nav-home">
            <Link to="/">
              <Icon type="home" />
              <span>Home</span>
            </Link>
          </MenuItem>
          <SubMenu
            key="subRepr"
            title={
              <span>
                <Icon type="star-o" />
                <span>Representante Comercial</span>
              </span>
            }>
            <MenuItem key="nav-repr-pedidos">
              <Link to="/pedidos" onClick={event => resetTabs()}>
                Pedidos de Venda
              </Link>
            </MenuItem>
          </SubMenu>
          <SubMenu
            key="subVendasDistr"
            title={
              <span>
                <Icon type="bars" />
                <span>Vendas e Distribuição</span>
              </span>
            }>
            <MenuItem key="nav-vendas-distr-arq">
              <Link to="/arquivos-comercial">Arquivos Comerciais</Link>
            </MenuItem>
            <MenuItem key="nav-cadastro-promotoria">
              <Link to="/promotoria/index">
                <span>Promotoria</span>
              </Link>
            </MenuItem>
            <MenuItem key="nav-vendas-distr-agenda">
              <Link to="/promotoria/agenda">Agenda Promotoria</Link>
            </MenuItem>
          </SubMenu>
          <SubMenu
            key="subTabelaPreco"
            title={
              <span>
                <Icon type="tags" />
                <span>Tabela de Preço Unificada</span>
              </span>
            }>
            <MenuItem key="nav-tabela-preco">
              <Link to="/tabela-preco">Tabela de Preço</Link>
            </MenuItem>
          </SubMenu>
          <SubMenu
            key="subRelatorios"
            title={
              <span>
                <Icon type="appstore-o" />
                <span>Relatórios</span>
              </span>
            }>
            {PROMOTOR_RELATORIOS_LINK.map(item =>
              <MenuItem key={`nav-relatorios-${item.key}`}>
                <Link to={item.href} title={item.title || item.label}>
                  {item.label}
                </Link>
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            key="subLinks"
            title={
              <span>
                <Icon type="link" />
                <span>Links Úteis</span>
              </span>
            }>
            <MenuItem key="nav-links-produtos">
              <ExternalLink to="https://www.anjo.com.br/produtos">Catálogo de Produtos</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvaWdvRDloc1FMUlk?resourcekey=0-FkGzi6rE9fFRO_iPDcR03A">Catálogo de Cor</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-arsenal">
              <ExternalLink to="http://arsenal.anjo.com.br">Arsenal ANJO</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://www.anjo.com.br/fale-conosco/telefones">Telefones Úteis</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://anjotintas.zendesk.com/hc/pt-br/requests/new">Abertura SAC - ZENDESK</ExternalLink>
            </MenuItem>
          </SubMenu>
        </StyledMenu>
      </MenuWrapper>
    );
  }
}

export default withRouter(MenuPromotor);
