import React from "react";

export function renderOrClone(Element, props = {}) {
  if (React.isValidElement(Element)) {
    return React.cloneElement(Element, props);
  } else if (typeof Element === "function" && !Element?.prototype?.isReactComponent) {
    return Element(props);
  }

  return <Element {...props} />;
}

export function parsePagination(pagination) {
  const parsed = { total: 0 };
  if ("count" in pagination) parsed.total = +pagination.count;
  if ("limit" in pagination) parsed.pageSize = +pagination.limit;
  if ("page" in pagination) parsed.current = +pagination.page;

  return parsed;
}

export function compactPagination(pagination) {
  const compacted = { total: 0 };
  if ("total" in pagination) compacted.total = pagination.total;
  if ("current" in pagination) compacted.current = pagination.current;
  if ("pageSize" in pagination) compacted.pageSize = pagination.pageSize;

  return compacted;
}

export function compactSorter(sorter) {
  const compacted = {};
  if ("columnKey" in sorter) compacted.columnKey = sorter.columnKey;
  if ("field" in sorter) compacted.field = sorter.field;
  if ("order" in sorter) compacted.order = sorter.order;

  return compacted;
}

export function compactFilters(filters) {
  return Object.keys(filters).reduce((acc, k) => {
    if (!!filters[k] && (typeof filters[k] === "boolean" || typeof filters[k] === "number" || filters[k].length > 0)) {
      acc[k] = filters[k];
    }
    return acc;
  }, {});
}

export function parseFilter(field, value) {
  return { field, value };
}
