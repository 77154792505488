import React, { PureComponent } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { darken } from "polished";

import LogoWhite from "img/logoWhite.png";
import LogoWhiteCollapsed from "img/logoWhite_collapsed.png";

const NavLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primaryDark};
  border-right: 1px solid ${props => darken(0.02, props.theme.primaryDark)};
  height: ${props => props.theme.headerHeight};
`;

class Sidebar extends PureComponent {
  render() {
    const { collapsed = false, children } = this.props;

    return (
      <Layout.Sider
        className="sidebar"
        collapsible
        theme="light"
        width={262}
        collapsed={collapsed}
        onCollapse={collapsed => {}}
        trigger={null}>
        <NavLogoWrapper>
          <img src={collapsed ? LogoWhiteCollapsed : LogoWhite} alt="Anjo Tintas" />
        </NavLogoWrapper>
        {children}
      </Layout.Sider>
    );
  }
}

export default Sidebar;
