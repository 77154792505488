import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker, Table, Spin, Badge, Dropdown, Icon, Tag } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm } from "components/FullTable";
import { fetchApi } from "actions";
import CustomModal from "./Components/ModalHistorico"; // Importa o componente Modal personalizado

const FormItem = Form.Item;

@observer
class HistoricoMaquinasReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;
    
    this.ds = new TableDataSource({
      name: "Máquinas",
      uri: "/r/historico-maquinas",
      controlled: false,
    });

    this.state = {
      isMop,
      maquinas: [],
      isLoading: false,
      isVisible: false,
      maquinaSelected: {}
    };
  }

  async componentDidMount() {
    await this.listarHistoricoMaquinas();
  }

  async listarHistoricoMaquinas() {
    try {
      this.setState({ isLoading: true });
      const { data } = await fetchApi(`/r/historico-maquinas-listagem`);
      this.setState({ maquinas: data })
    } catch (error) {

    } finally {
      this.setState({ isLoading: false });
    }
  }

  isVisibleModal = (record) => {
    this.setState({ isVisible: true });
    this.setState({ maquinaSelected: record })
  }

  closeModal = () => {
    this.setState({ isVisible: false });
  }
  
  render() {
    const Indice = ({ record }) => {
      const handleClick = () => {
        this.isVisibleModal(record);
      };
    
      return <Tag color="blue" onClick={handleClick}>SELECIONAR</Tag>;
    };

    const columns = [
      {
        title: 'ID',
        dataIndex: 'r_e_c_n_o_',
        key: 'r_e_c_n_o_',
        width: "5%",
      },
      {
        title: 'COD PRODUTO',
        dataIndex: 'zy4_modelo',
        key: 'zy4_modelo',
        width: "10%",
      },
      {
        title: 'NUM SERIE',
        dataIndex: 'zy4_nserie',
        key: 'zy4_nserie',
        width: "20%",
      },
      {
        title: 'COD CLIENTE',
        dataIndex: 'zy4_codcli',
        key: 'zy4_codcli',
        width: "10%",
      },
      {
        title: 'NOME CLIENTE',
        dataIndex: 'zy4_client',
        key: 'zy4_client',
        width: "50%",
      },
      {
        title: 'Status',
        width: "50%",
        render: (text, record) => <Indice record={record} />
      }
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name} />
        <Spin spinning={this.state.isLoading} size="large">
          <Table columns={columns} loading={false}  dataSource={this.state.maquinas}/>
        </Spin>
        <CustomModal visible={this.state.isVisible} onClose={this.closeModal} maquina={this.state.maquinaSelected}/>
      </div>
    );
  }
}

export default withStore(HistoricoMaquinasReportScreen);
