import React from "react";
import { Tag, Icon } from "antd";
import styled from "styled-components";

const StyledTag = styled(({ invalid, ...props }) => <Tag {...props} />)`
  &.ant-tag {
    margin: 2px 0 0 0;
    cursor: default;

    ${props => !!props.invalid && `color: #333;`}
  }
`;

export const ProdutoTagGroup = styled.div`
  text-align: ${props => props.textAlign || "left"};

  & > .ant-tag + .ant-tag {
    margin-left: 7px;
  }
`;

const ProdutoTag = ({ invalid = false, color = null, title = null, children, ...props }) => {
  return (
    <StyledTag
      title={!!title ? (!!invalid ? "Inválido: " + title : title) : null}
      color={!!invalid ? `#f4c133` : color}
      invalid={!!invalid}
      {...props}>
      {!!invalid && <Icon type="exclamation-circle" style={{ marginRight: 3 }} />}
      {children}
    </StyledTag>
  );
};

export default ProdutoTag;
