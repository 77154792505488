import React, { Component } from "react";
import { Modal, Button, Spin, Icon } from "antd";
import { fetchApi } from "actions";

const antIcon = <Icon type="loading" style={{ fontSize: 42 }} spin />;

class ModalHistorico extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            maquina: {}
        };
    }

    handleOk = () => {
        // Lógica para lidar com o botão "OK" do modal
        this.props.onClose();
    };

    handleCancel = () => {
        // Lógica para lidar com o botão "Cancelar" do modal
        this.props.onClose();
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.listaDadosMaquina();
        }
    }

    async listaDadosMaquina() {
        try {
            this.setState({ isLoading: true });
            const { data } = await fetchApi(
                `/r/historico-maquinas-selecionada/${this.props.maquina.r_e_c_n_o_}`
            );
            this.setState({ maquina: data });
        } catch (error) {
            // Lógica para tratamento de erro
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { visible, maquina } = this.props;

        return (
            <Modal
                title={`Máquina : ${this.state?.maquina?.z2?.zy4_modelo ? this.state?.maquina?.z2?.zy4_modelo : ''}`}
                visible={visible}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.handleOk}>
                        OK
                    </Button>,
                ]}
            >
                <Spin spinning={this.state.isLoading} indicator={antIcon}>
                    <p>
                        ID: {this.state.maquina?.z2?.r_e_c_n_o_}
                    </p>
                    <p>Data: {this.state.maquina?.data}</p>
                    <p>Máquina selecionada: {this.state.maquina?.z2?.zy4_modelo}</p>
                    <p>
                        Número de série: {this.state.maquina?.z2?.zy4_nserie}
                    </p>
                    <p>
                        Cliente: {this.state.maquina?.z2?.zy4_codcli} - {this.state.maquina?.z2?.zy4_client}
                    </p>
                    <p>
                        Nome Gerente: {this.state.maquina?.zy3?.zy3_nomger}
                    </p>
                    <p>
                        Nome Representante: {this.state.maquina?.zy3?.zy3_nrepre}
                    </p>
                    <p>
                        Nome Gerente: {this.state.maquina?.zy3?.zy3_nomger}
                    </p>
                    <div>
                        {this.state.maquina?.z2?.zy4_modali === "V" && <p>Modalidade: Venda subsidiada</p>}
                        {this.state.maquina?.z2?.zy4_modali === "C" && <p>Modalidade: Comodato</p>}
                        {this.state.maquina?.z2?.zy4_modali === "P" && <p>Modalidade: Própria</p>}
                        {this.state.maquina?.z2?.zy4_modali === "A" && <p>Modalidade: Aluguel</p>}
                    </div>
                    <div>
                        {this.state.maquina?.z2?.zy4_situac === "N" && <p>Situação: Nova</p>}
                        {this.state.maquina?.z2?.zy4_situac === "U" && <p>Situação: Usada</p>}
                        {this.state.maquina?.z2?.zy4_situac === "C" && <p>Situação: Conversão</p>}
                    </div>
                    <h3>Maquina está: <strong>{this.state.maquina?.status}</strong></h3>
                    {/* <h3><strong>Total de pedidos desse modelo:</strong></h3> */}
                </Spin>
            </Modal>
        );
    }
}

export default ModalHistorico;
