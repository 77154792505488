import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm, Toolbar } from "components/FullTable";

const FormItem = Form.Item;

@observer
class ProdutosTrabalhadoPromotoria extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Produtos Trabalhados Promotoria",
      uri: "/r/produtos-trabalhados-promotoria",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          data_promotoria$0: "date",
          data_promotoria$1: "date",
        }}
        filterParser={{
          data_promotoria$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          data_promotoria$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Filtrar por Data Promotoria">
              {form.getFieldDecorator("data_promotoria$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("data_promotoria$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
            <FormItem style={{ float: "right", margin: 0 }}>
              <Toolbar.Button
                  disabled={dataSource.isLoading}
                  size="default"
                  icon="reload"
                  ghost={true}
                  onClick={() => dataSource.fetch()}>
                  Atualizar
              </Toolbar.Button>
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const columns = [
      {
        title: "Promotoria",
        dataIndex: "Vendas.promotoria",
        key: "Vendas.promotoria",
        sorter: false,
        filteredValue: this.ds.filterValue("promotoria"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Promotoria" {...props} />,
        filterParser: filter => ({ ...filter, field: "promotoria", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Data Promotoria",
        dataIndex: "Vendas.data_promotoria",
        dataType: "date",
        key: "Vendas.data_promotoria",
        sorter: true,
        width: "9.08%",
        filteredValue: this.ds.filterValue("data_promotoria"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data Promotoria (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Vendas.data_promotoria",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Cliente",
        dataIndex: "Vendas.cliente",
        key: "Vendas.cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Representante",
        dataIndex: "Vendas.representante",
        key: "Vendas.representante",
        sorter: false,
        filteredValue: this.ds.filterValue("representante"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "representante", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Produto",
        dataIndex: "Vendas.produto",
        key: "Vendas.produto",
        sorter: false,
        filteredValue: this.ds.filterValue("produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "produto", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Volume/Receita Antes",
        dataIndex: "Vendas.volumeant",
        key: "Vendas.volumeant",
        sorter: false,
        filteredValue: this.ds.filterValue("volumeant"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Volume/Receita Antes" {...props} />,
        filterParser: filter => ({ ...filter, field: "volumeant", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Volume/Receita Depois",
        dataIndex: "Vendas.volumepos",
        key: "Vendas.volumepos",
        sorter: false,
        filteredValue: this.ds.filterValue("volumepos"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Volume/Receita Depois" {...props} />,
        filterParser: filter => ({ ...filter, field: "volumepos", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      }
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.Curso.id}` }
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter seus cursos e treinamentos no momento"
        />
      </div>
    );
  }
}

export default withStore(ProdutosTrabalhadoPromotoria);
