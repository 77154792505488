import React from "react";
import hoistStatics from "hoist-non-react-statics";
import UIStore from "./stores/UIStore";

const withStore = Component => {
  class ComponentWithStore extends React.Component {
    static displayName = `withStore(${Component.displayName || Component.name})`;
    render() {
      return <Component {...this.props} store={UIStore} />;
    }
  }

  return hoistStatics(ComponentWithStore, Component);
};

export default withStore;
