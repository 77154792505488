import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { LocaleProvider, Modal } from "antd";
import { ThemeProvider } from "styled-components";
import { autorun, toJS } from "mobx";
import { observer } from "mobx-react";
import classnames from "classnames";
import _throttle from "lodash/throttle";

import { getViewportSize } from "@util";
import theme from "@theme";

import AuthRouter from "routes/Auth";
import AppRouter from "routes/App";

import { TableDataSource } from "components/FullTable";
import ExportableModal from "components/ExportableModal";
import FormPromotoriaPintores from "screens/common/Promotoria/FormPromotoriaPintores";

let pt_BR = require("antd/lib/locale-provider/pt_BR");
pt_BR.DatePicker.lang.monthBeforeYear = true;

const history = createBrowserHistory();

@observer
export default class App extends Component {
  constructor(props) {
    super(props);

    this.updateWindowSize = _throttle(this.updateWindowSize.bind(this), 300);

    autorun(
      () => {
        if (this.props.store.sidebarCollapsed) {
          if (!document.body.classList.contains("sidebar-collapsed")) {
            document.body.classList.add("sidebar-collapsed");
          }
        } else {
          document.body.classList.remove("sidebar-collapsed");
        }
      },
      { delay: 150 }
    );

    autorun(
      () => {
        clearTimeout(this._showMessage);

        if (!!this.props.store.warningMessage && "mensagem" in this.props.store.warningMessage) {
          this._showMessage = setTimeout(this.showMessage, 250);
        }
      },
      { delay: 250 }
    );

    this.registerLocation(history?.location?.pathname, history?.action);

    history.listen((location, action = null) => {
      this.registerLocation(location?.pathname, action);

      const { store } = props;

      if (store.isReady && store.isLogged) {
        store.ping();
      }

      window.gtag("config", window._ga, {
        page_path: location?.pathname,
      });
    });

    this.state = {};
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowSize);
    this.updateWindowSize();

    this.registerLocation(history?.location?.pathname, history?.action);

    const { store } = this.props;

    if (store.isReady && store.isLogged) {
      store.ping();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  }
  registerLocation(pathname, action = null) {
    const { store } = this.props;

    store.location = !!pathname ? pathname : "/";
    TableDataSource.restoreLocation = !!pathname ? pathname : "/";

    store.locationAction = !!action ? action : "PUSH";
    TableDataSource.restoreAction = !!action ? action : "PUSH";
  }
  updateWindowSize() {
    const { store } = this.props;
    store.viewport = getViewportSize();

    if (+store.viewport.width < 1152) {
      if (!store.sidebarCollapsed) {
        store.sidebarCollapsed = true;
      }
      if (!store.hideMenuButton) {
        store.hideMenuButton = true;
      }
    } else if (!!store.hideMenuButton) {
      store.hideMenuButton = false;
    }
  }
  showMessage = () => {
    const { location } = this.props.store;
    const warningMessage = toJS(this.props.store.warningMessage);
    if (!!warningMessage && "mensagem" in warningMessage && location.indexOf(warningMessage.origem) !== -1) {
      Modal.warning({
        title: warningMessage.titulo || "Atenção",
        content: warningMessage.mensagem,
        onOk: () => {
          if (!!warningMessage.url) {
            window.open(warningMessage.url, "_blank");
          }
        },
      });

      this.props.store.warningMessage = null;
    }
  };
  render() {
    const { store } = this.props;
    if (!store.isReady) {
      return null;
    }

    return (
      <LocaleProvider locale={pt_BR}>
        <Router history={history}>
          <ThemeProvider theme={theme.main}>
            <div
              className={classnames("App", {
                App__cliente: store.isLogged && store.loginData.tipo === "CLIENTE",
                App__diretor: store.isLogged && store.loginData.tipo === "NEXXUS_DIRETOR",
                App__vendedor: store.isLogged && store.loginData.tipo === "VENDEDOR",
                App__operador: store.isLogged && store.loginData.tipo === "OPERADOR_TELEVENDAS",
                App__promotor: store.isLogged && store.loginData.tipo === "NEXXUS_PROMOTOR",
              })}>
              <Switch>
                <Route path="/auth" render={props => <AuthRouter {...props} store={store} />} />
                <Route path="/cadastrar-pintores/:promotoria" component={FormPromotoriaPintores}/>
                <Route
                  path="/"
                  render={props =>
                    store.isLogged ? (
                      <AppRouter {...props} store={store} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/auth/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
              </Switch>
              <ExportableModal exportable={this.props.exportable} />
            </div>
          </ThemeProvider>
        </Router>
      </LocaleProvider>
    );
  }
  _showMessage;
}
