import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Layout } from "antd";
import styled from "styled-components";
import _throttle from "lodash/throttle";

import { isDev, isStaging } from "@env";

import Header from "components/Header";
import UserMenu from "components/UserMenu";
import Sidebar from "components/Sidebar";
import MenuCliente from "./menus/MenuCliente";
import MenuVendedor from "./menus/MenuVendedor";
import MenuOperador from "./menus/MenuOperador";
import MenuDiretor from "./menus/MenuDiretor";
import MenuSupervisor from "./menus/MenuSupervisor";
import MenuGerenteTecnico from "./menus/MenuGerenteTecnico";

import "./AppLayout.less";
import MenuPromotor from "./menus/MenuPromotor";

const ContentWrapper = styled(Layout.Content)`
  &.ant-layout-content {
    background-color: #fff !important;
    height: calc(100vh - ${props => props.theme.headerHeight});
    overflow-x: hidden;
    overflow-y: auto;
    padding: 18px 28px;
  }
`;

export default class AppLayout extends PureComponent {
  constructor(props) {
    super(props);

    this.scrollUpdate = _throttle(this.scrollUpdate.bind(this), 300);
  }
  componentDidMount() {
    const appLayout = document.getElementById("app-layout");
    if (!!appLayout) appLayout.addEventListener("scroll", this.scrollUpdate);

    document.body.addEventListener("restoreScroll", this.restoreScroll, false);
  }
  componentWillUnmount() {
    const appLayout = document.getElementById("app-layout");
    if (!!appLayout) appLayout.removeEventListener("scroll", this.scrollUpdate);

    document.body.removeEventListener("restoreScroll", this.restoreScroll, false);
  }
  scrollUpdate(event) {
    if ("sessionStorage" in window) {
      const { location } = window;
      window.sessionStorage.setItem(
        `app-layout:${location.pathname}:scrollTop`,
        JSON.stringify(event.target.scrollTop)
      );
    }
  }
  restoreScroll(event) {
    if ("sessionStorage" in window) {
      const { location } = window;
      const st = window.sessionStorage.getItem(`app-layout:${location.pathname}:scrollTop`);
      if (!!st && +st > 0) {
        const appLayout = document.getElementById("app-layout");
        if (!!appLayout) appLayout.scrollTop = Math.max(+st, 0);
      }
    }
  }
  render() {
    const {
      collapsed = false,
      hideMenuButton = false,
      tipo = "CLIENTE",
      isB2B = false,
      isTipoVendedor = false,
      isTipoVendaInterna = false,
      loginName,
      tipoDiretor,
      tipoSupervisor,
      tipoGerenteTecnico,
      Diretor_unidade_negocio,
      isOperadorAdmin = false,
      isNexxusPro = false,
      resetTabs,
      onUserMenu,
      onCollapse,
      isGerente,
      isPromotor
    } = this.props;
    let title = tipo === "CLIENTE" ? "Portal do Cliente - ANJO NEXXUS" : "ANJO NEXXUS";
    if (isDev) {
      title = `DEV ${title}`;
    } else if (isStaging) {
      title = `HOMOLOGAÇÃO ${title}`;
    }

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Sidebar collapsed={collapsed}>
          {tipo === "CLIENTE" && <MenuCliente isB2B={isB2B} collapsed={collapsed} resetTabs={resetTabs} />}
          {tipo === "NEXXUS_DIRETOR" && (
            <MenuDiretor tipoDiretor={tipoDiretor} isB2B={isB2B} Diretor_unidade_negocio={Diretor_unidade_negocio} collapsed={collapsed} resetTabs={resetTabs} isNexxusPro={isNexxusPro} isGerente={isGerente}/>
          )}
          {(tipo === "NEXXUS_SUPERVISOR_TINTOMETRICO" || tipo === "NEXXUS_SUPERVISOR_GERAL") && (
            <MenuSupervisor tipoSupervisor={tipoSupervisor} isB2B={isB2B} collapsed={collapsed} resetTabs={resetTabs} />
          )}
          {(tipo === "NEXXUS_GERENTE_TECNICO" || tipo === "NEXXUS_GERENTE_COMERCIAL") && (
            <MenuGerenteTecnico tipoGerenteTecnico={tipoGerenteTecnico} isB2B={isB2B} collapsed={collapsed} resetTabs={resetTabs} />
          )}
          {(tipo === "VENDEDOR" || tipo === "VENDA_INTERNA") && (
            <MenuVendedor
              isB2B={isB2B}
              isTipoVendedor={isTipoVendedor}
              isTipoVendaInterna={isTipoVendaInterna}
              isNexxusPro={isNexxusPro}
              collapsed={collapsed}
              resetTabs={resetTabs}
            />
          )}
          {tipo === "OPERADOR_TELEVENDAS" && <MenuOperador isB2B={isB2B} collapsed={collapsed} resetTabs={resetTabs} isNexxusPro={isNexxusPro} />}
          {isPromotor && <MenuPromotor collapsed={collapsed} resetTabs={resetTabs}/>}
        </Sidebar>
        <Layout>
          <Header hideMenuButton={hideMenuButton} collapsed={collapsed} onCollapse={onCollapse}>
            <UserMenu name={loginName} isOperadorAdmin={isOperadorAdmin} onClick={onUserMenu} resetTabs={resetTabs} />
          </Header>
          <ContentWrapper className="app-content" id="app-layout">
            <main>{this.props.children}</main>
          </ContentWrapper>
        </Layout>
      </Layout>
    );
  }
  _layoutRef;
}
