import React, { Component, PureComponent, Fragment } from "react";
import { Dropdown, Button, Icon, Menu } from "antd";
import { observer } from "mobx-react";
import classnames from "classnames";

import RequestForm from "../RequestForm";

function flatColumns(columns) {
  return columns.reduce((acc, c) => {
    if ("children" in c) {
      return [...acc, ...c.children];
    }

    return [...acc, c];
  }, []);
}

export class ToolbarActions extends PureComponent {
  render() {
    const { children, className, ...otherProps } = this.props;

    return (
      <div className={classnames("full-table--actions", className)} {...otherProps}>
        {children}
      </div>
    );
  }
}

export class ActionButton extends PureComponent {
  render() {
    const { children, menu = null, size = "small", ...otherProps } = this.props;

    const AB = (
      <Button size={size} type="primary" {...otherProps}>
        {children}
        {!!menu && <Icon type="down" />}
      </Button>
    );

    return !!menu ? <Dropdown overlay={menu}>{AB}</Dropdown> : AB;
  }
}

ActionButton.Exportar = ({ onClick, hasFilter = false, controlled = false, ...props }) => {
  const filteredMenu = !!hasFilter ? (
    <Menu onClick={onClick}>
      <Menu.Item key="filter-active">Apenas resultados do filtro</Menu.Item>
      {!controlled && <Menu.Item key="filter-all">Todos os registros</Menu.Item>}
    </Menu>
  ) : null;

  return (
    <ActionButton
      onClick={!filteredMenu ? onClick : null}
      menu={filteredMenu}
      title="Exportar os registros em formato Excel"
      icon="download"
      {...props}>
      Exportar
    </ActionButton>
  );
};

ActionButton.Imprimir = ({ onClick, hasFilter = false, controlled = false, ...props }) => {
  const filteredMenu = !!hasFilter ? (
    <Menu onClick={onClick}>
      <Menu.Item key="filter-active">Apenas resultados do filtro</Menu.Item>
      {!controlled && <Menu.Item key="filter-all">Todos os registros</Menu.Item>}
    </Menu>
  ) : null;

  return (
    <ActionButton
      onClick={!filteredMenu ? onClick : null}
      menu={filteredMenu}
      title="Imprimir os registros"
      icon="printer"
      {...props}>
      Imprimir
    </ActionButton>
  );
};

@observer
export default class Toolbar extends Component {
  state = {
    action: "print",
    filter: "all",
  };
  cleanFilters = () => {
    this.props.onUpdateDataSource(null, {}, null);
  };
  requestAction = (action = "print", filter = "all", onSubmit) => {
    filter = !!filter ? filter.replace(/^(filter-)/, "") : "all";

    const { dataSource, extraData } = this.props;
    if (action === "export") {
      dataSource.export({
        title: dataSource.name || "Exportação",
        source: () => {
          const uri = dataSource
            .actionUri()
            .replace(/(page=[0-9]+)/gi, "")
            .replace(/(\?&)/gi, "?");

          const filterParams = filter === "active" && dataSource.hasFilter ? dataSource.dataFilter : {};

          return { uri, data: filterParams };
        },
        columns: flatColumns(this.props.columns)
          .filter(c => c.key !== "actions" && (!("export" in c) || !!c.export))
          .map(c => {
            const type = c.dataType || c.renderType || "text";

            return {
              type,
              key: c.dataIndex || c.key,
              label: c.dataLabel || c.title || c.key,
              width: c.width || "auto",
              parser:
                type === "text" && !!c.dataParser && typeof c.dataParser === "function"
                  ? c.dataParser.toString()
                  : null,
              className: !!c.className
                ? typeof c.className === "function"
                  ? c.className()
                  : c.className
                : "text-center",
              ...c.export,
            };
          }),
        extraData,
      });
      return;
    }

    this.setState({ action, filter }, () => !!onSubmit && onSubmit());
  };
  render() {
    const {
      type = "normal",
      size = "small",
      exportable = true,
      printable = true,
      columns = [],
      children,
      dataSource,
      style,
    } = this.props;
    const filterParams = this.state.filter === "active" && dataSource.hasFilter ? dataSource.dataFilter : {};

    return (
      <ToolbarActions style={style}>
        <RequestForm
          action={dataSource
            .actionUri({ [this.state.action]: true })
            .replace(/(page=[0-9]+)/gi, "")
            .replace(/(\?&)/gi, "?")}
          params={{
            ...filterParams,
            columns: flatColumns(columns)
              .filter(c => c.key !== "actions" && (!("export" in c) || !!c.export))
              .map(c => `${c.dataIndex || c.key}|${c.dataLabel || c.title || c.key}`),
          }}>
          {({ submitForm }) => (
            <Fragment>
              {type !== "simple" && !!exportable && (
                <ActionButton.Exportar
                  size={size}
                  onClick={event => this.requestAction("export", event.key, submitForm)}
                  hasFilter={dataSource.hasFilter}
                  controlled={!!dataSource.controlled}
                />
              )}
              {type !== "simple" && !!printable && (
                <ActionButton.Imprimir
                  size={size}
                  onClick={event => this.requestAction("print", event.key, submitForm)}
                  hasFilter={dataSource.hasFilter}
                  controlled={!!dataSource.controlled}
                />
              )}
              {typeof children === "function" ? children({ submitForm, requestAction: this.requestAction }) : children}
              {type !== "simple" && !!dataSource.hasFilter && (
                <ActionButton size={size} icon="delete" ghost={true} onClick={this.cleanFilters}>
                  Limpar Filtros
                </ActionButton>
              )}
            </Fragment>
          )}
        </RequestForm>
      </ToolbarActions>
    );
  }
}
