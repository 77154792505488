import React, { Component, Fragment } from "react";
import { Modal, Progress, Button } from "antd";
import { observer } from "mobx-react";

import "./ExportableModal.less";

@observer
export default class ExportableModal extends Component {
  render() {
    const { exportable, width = 470, ...otherProps } = this.props;

    return (
      <Modal
        centered
        title={
          <Fragment>
            <strong>Aguarde:</strong> {exportable.action === "print" ? "Gerando Impressão..." : "Gerando Arquivo..."}
          </Fragment>
        }
        visible={!!exportable.started}
        width={width}
        footer={null}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        wrapClassName="exportable-modal"
        {...otherProps}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Progress percent={Math.min(+exportable.progress, 100)} strokeWidth={15} status="active" />
          <Button disabled={!exportable.canCancel} onClick={() => exportable.cancel()} size="small" type="danger">
            Cancelar
          </Button>
        </div>
      </Modal>
    );
  }
}
