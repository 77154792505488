import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker, Dropdown } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import Select, { Option } from "components/Select";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm } from "components/FullTable";

import AnaliseVolumeItemReport from "components/Report/AnaliseVolumeItemReport";

const FormItem = Form.Item;

@observer
class AnaliseVolumeReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Análise de Volume de Maquinas",
      uri: "/r/analise-volume",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          data$0: "date",
          data$1: "date",
          group$2: "select",
        }}
        filterParser={{
          data$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          data$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
          group$2: filter => ({ ...filter, value: filter.value, type: "radio" }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Filtrar por Data de Faturamento da máquina">
              {form.getFieldDecorator("data$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("data$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("group$2")(
                <Select style={{width:'150px'}} placeholder="Visualização por">
                  <Option value="maquina">Máquina</Option>
                  <Option value="grupo">Grupo Coligado</Option>
                </Select>
              )}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  
  render() {
    var columns = [
      {
        title: "Código do Cliente",
        dataIndex: "AnaliseVolume.codcliente",
        key: "AnaliseVolume.codcliente",
        sorter: false,
        filteredValue: this.ds.filterValue("codcliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código do cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "codcliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Descrição do Cliente",
        dataIndex: "AnaliseVolume.cliente",
        key: "AnaliseVolume.cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Descrição do Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Nome do Gerente",
        dataIndex: "AnaliseVolume.gerente",
        key: "AnaliseVolume.gerente",
        sorter: false,
        filteredValue: this.ds.filterValue("gerente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome do Gerente" {...props} />,
        filterParser: filter => ({ ...filter, field: "gerente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Código do Representante",
        dataIndex: "AnaliseVolume.codvendedor",
        key: "AnaliseVolume.codvendedor",
        sorter: false,
        filteredValue: this.ds.filterValue("codvendedor"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código do Representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "codvendedor", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Nome do Representante",
        dataIndex: "AnaliseVolume.vendedor",
        key: "AnaliseVolume.vendedor",
        sorter: false,
        filteredValue: this.ds.filterValue("vendedor"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome do Representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "vendedor", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Modalidade da Máquina",
        dataIndex: "modalidade",
        key: "modalidade",
        sorter: false,
        filteredValue: this.ds.filterValue("modalidade"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Modalidade da Máquina" {...props} />,
        filterParser: filter => ({ ...filter, field: "modalidade", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Data Faturamento",
        dataIndex: "AnaliseVolume.datafat",
        dataType: "date",
        key: "AnaliseVolume.datafat",
        sorter: true,
        width: "9.08%",
        filteredValue: this.ds.filterValue("datafat"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data Faturamento (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "AnaliseVolume.datafat",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Faturamento",
        dataIndex: "faturamento",
        key: "faturamento",
        sorter: false,
        filteredValue: this.ds.filterValue("faturamento"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Faturamento" {...props} />,
        filterParser: filter => ({ ...filter, field: "faturamento", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      // {
      //   title: "Volume",
      //   dataIndex: "AnaliseVolume.volume",
      //   key: "AnaliseVolume.volume",
      //   sorter: false,
      //   filteredValue: this.ds.filterValue("AnaliseVolume.volume"),
      //   filterDropdown: props => <TextFilter placeholder="Filtrar por Volume" {...props} />,
      //   filterParser: filter => ({ ...filter, field: "AnaliseVolume.volume", value: `%${filter.value}`, op: "LIKE" }),
      //   width: "9.08%",
      // },
      {
        title: "Quantidade",
        dataIndex: "quantidade",
        key: "quantidade",
        sorter: true,
        width: "9.08%",
      },
    ];
    if (this.ds.filters.group$2 == 'grupo') {
      columns = [
        {
          title: "Grupo",
          dataIndex: "AnaliseVolume.grupo",
          key: "AnaliseVolume.grupo",
          sorter: false,
          filteredValue: this.ds.filterValue("grupo"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Código do cliente" {...props} />,
          filterParser: filter => ({ ...filter, field: "grupo", value: `%${filter.value}`, op: "LIKE" }),
          width: "9.08%",
        },
        {
          title: "Qtd. Máquinas Imobiliárias",
          dataIndex: "qtd_imobiliaria",
          key: "qtd_imobiliaria",
          sorter: false,
          filteredValue: this.ds.filterValue("qtd_imobiliaria"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Qtd. Máquinas Imobiliárias" {...props} />,
          filterParser: filter => ({ ...filter, field: "qtd_imobiliaria", value: `%${filter.value}`, op: "LIKE" }),
          width: "9.08%",
        },
        {
          title: "Qtd. Máquinas Automotivas",
          dataIndex: "qtd_automotiva",
          key: "qtd_automotiva",
          sorter: false,
          filteredValue: this.ds.filterValue("qtd_automotiva"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Qtd. Máquinas Automotivas" {...props} />,
          filterParser: filter => ({ ...filter, field: "qtd_automotiva", value: `%${filter.value}`, op: "LIKE" }),
          width: "9.08%",
        },
        {
          title: "Modalidade da Máquina",
          dataIndex: "AnaliseVolume.modalidade",
          key: "AnaliseVolume.modalidade",
          sorter: false,
          filteredValue: this.ds.filterValue("modalidade"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Modalidade da Máquina" {...props} />,
          filterParser: filter => ({ ...filter, field: "modalidade", value: `%${filter.value}`, op: "LIKE" }),
          width: "9.08%",
        },
        {
          title: "Faturamento",
          dataIndex: "faturamento",
          key: "faturamento",
          sorter: false,
          filteredValue: this.ds.filterValue("faturamento"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Faturamento" {...props} />,
          filterParser: filter => ({ ...filter, field: "faturamento", value: `%${filter.value}`, op: "LIKE" }),
          width: "9.08%",
        },
        {
          title: "Volume",
          dataIndex: "volume",
          key: "volume",
          sorter: false,
          filteredValue: this.ds.filterValue("volume"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Volume" {...props} />,
          filterParser: filter => ({ ...filter, field: "volume", value: `%${filter.value}`, op: "LIKE" }),
          width: "9.08%",
        },
      ];

      return (
        <div className="nfs-screen">
          <PageTitle title={this.ds.name}></PageTitle>
          <FullTable
            className="full-table__compact"
            beforeComponent={this.renderSearchForm}
            loading={this.props.store.isLoading}
            rowKey={item => `${item.id}` }
            columns={columns}
            dataSource={this.ds}
            errorMessage="Não foi possível obter suas análises no momento"
          />
        </div>
      );
    }
    
    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}></PageTitle>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.id}` }
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas análises no momento"
        />
      </div>
    );
  }
}

export default withStore(AnaliseVolumeReportScreen);
