/**
 * @flow
 */

import { action, computed, observable, autorun, toJS } from "mobx";
import { fetchPing } from "actions";
import { APP_VERSION, getApiUrl, setAuthToken } from "@env";

const READY_COUNT = 2;
const STORAGE_KEY = "@PortalANJO::";
const lsKey = k => STORAGE_KEY + `${k}`;

class UIStore {
  @observable readyCount: number = 0;
  @observable sidebarCollapsed: boolean = false;
  @observable hideMenuButton: boolean = false;
  @observable firstPing: boolean = true;
  @observable modalCampJun: boolean = true;
  @observable isOnline: boolean = false;

  @observable isLoading: boolean = false;
  @observable isSending: boolean = false;

  @observable location: string = "/";
  @observable locationAction: string = "PUSH";
  @observable printFormAction: string = "/";
  @observable loginToken: string = null;
  @observable loginData: Object = null;
  @observable clienteData: Object = null;
  @observable diretorData: Object = null;
  @observable supervisorData: Object = null;
  @observable vendedorData: Object = null;
  @observable operadorData: Object = null;
  @observable promotorData: Object = null;
  @observable relacionadoData: Object = null;
  @observable viewport: Object = null;
  @observable.shallow warningMessage: Object = null;
  @observable.shallow trackedTabs: Object = {};

  constructor() {
    const restoredLoginData = localStorage.getItem(lsKey(`loginData`));
    if (!!restoredLoginData) {
      this.setLoginData(JSON.parse(restoredLoginData));

      if (!!this.loginData) {
        if (this.loginData.tipo === "CLIENTE" && this.clienteData === null) {
          const restoredClienteData = localStorage.getItem(lsKey(`clienteData`));
          if (!!restoredClienteData) this.clienteData = JSON.parse(restoredClienteData);

        } else if (this.loginData.tipo === "NEXXUS_DIRETOR" && this.diretorData === null) {
          const restoredDiretorData = localStorage.getItem(lsKey(`diretorData`));
          if (!!restoredDiretorData) this.diretorData = JSON.parse(restoredDiretorData);

        } else if ((this.loginData.tipo === "NEXXUS_SUPERVISOR_TINTOMETRICO" || this.loginData.tipo === "NEXXUS_SUPERVISOR_GERAL") && this.supervisorData === null) {
          const restoredSupervisorData = localStorage.getItem(lsKey(`supervisorData`));
          if (!!restoredSupervisorData) this.supervisorData = JSON.parse(restoredSupervisorData);

        } else if (this.loginData.tipo === "OPERADOR_TELEVENDAS" && this.operadorData === null) {
          const restoredOperadorData = localStorage.getItem(lsKey(`operadorData`));
          if (!!restoredOperadorData) this.operadorData = JSON.parse(restoredOperadorData);

        } else if (this.loginData.tipo === "NEXXUS_PROMOTOR" && this.promotorData === null) {
          const restoredPromotorData = localStorage.getItem(lsKey(`promotorData`));
          if (!!restoredPromotorData) this.vendedorData = JSON.parse(restoredPromotorData);
          
        } else if (this.vendedorData === null) {
          const restoredVendedorData = localStorage.getItem(lsKey(`vendedorData`));
          if (!!restoredVendedorData) this.vendedorData = JSON.parse(restoredVendedorData);
        }
      }
    }
    const restoredSettings = localStorage.getItem(lsKey(`settings`));
    if (!!restoredSettings) {
      const settings = JSON.parse(restoredSettings);
      if ("sidebarCollapsed" in settings) this.sidebarCollapsed = settings.sidebarCollapsed;
    }
    this.readyCount++;

    this.onConnectionChange = this.onConnectionChange.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    window.addEventListener("online", this.onConnectionChange);
    window.addEventListener("offline", this.onConnectionChange);

    const autoRunAsync = { delay: 200 };

    autorun(() => {
      const persistLogin = this.isLogged ? Object.assign(toJS(this.loginData), { token: this.loginToken }) : null;
      localStorage.setItem(lsKey(`loginData`), JSON.stringify(persistLogin));
    }, autoRunAsync);

    autorun(() => localStorage.setItem(lsKey(`clienteData`), JSON.stringify(this.clienteData)), autoRunAsync);
    autorun(() => localStorage.setItem(lsKey(`diretorData`), JSON.stringify(this.diretorData)), autoRunAsync);
    autorun(() => localStorage.setItem(lsKey(`supervisorData`), JSON.stringify(this.supervisorData)), autoRunAsync);
    autorun(() => localStorage.setItem(lsKey(`vendedorData`), JSON.stringify(this.vendedorData)), autoRunAsync);
    autorun(() => localStorage.setItem(lsKey(`operadorData`), JSON.stringify(this.operadorData)), autoRunAsync);
    autorun(() => localStorage.setItem(lsKey(`promotorData`), JSON.stringify(this.promotorData)), autoRunAsync);

    autorun(() => {
      const persistSettings = {
        sidebarCollapsed: this.sidebarCollapsed,
      };
      localStorage.setItem(lsKey(`settings`), JSON.stringify(persistSettings));
    }, autoRunAsync);

    this.onConnectionChange();
    this.readyCount++;
  }

  @computed
  get isReady() {
    return this.readyCount === READY_COUNT;
  }

  @computed
  get isLogged() {
    const hasToken = !!this.loginToken && !!this.loginData && !!this.loginData.tipo;
    return (
      hasToken &&
      ((this.loginData.tipo === "NEXXUS_DIRETOR" && !!this.diretorData) ||
        (this.loginData.tipo === "CLIENTE" && !!this.clienteData) ||
        (this.loginData.tipo === "NEXXUS_PROMOTOR" && !!this.promotorData) ||
        ((this.loginData.tipo === "NEXXUS_SUPERVISOR_TINTOMETRICO" || this.loginData.tipo === "NEXXUS_SUPERVISOR_GERAL") && !!this.supervisorData) ||
        (this.loginData.tipo === "OPERADOR_TELEVENDAS" && !!this.operadorData) ||
        !!this.vendedorData)
    );
  }

  @computed
  get isDiretor() {
    return this.isLogged && this.loginData.tipo === "NEXXUS_DIRETOR"; //&& this.diretorData?.tipo === "DIRETOR";
  }

  @computed
  get isCliente() {
    return this.isLogged && this.loginData.tipo === "CLIENTE";
  }

  @computed
  get isVendedor() {
    return this.isLogged && (this.loginData.tipo === "VENDEDOR" || this.loginData.tipo === "VENDA_INTERNA");
  }

  @computed
  get isOperador() {
    return this.isLogged && this.loginData.tipo === "OPERADOR_TELEVENDAS";
  }

  @computed
  get isSupervisor() {
    return this.isLogged && (this.loginData.tipo === "NEXXUS_SUPERVISOR_TINTOMETRICO" || this.loginData.tipo === "NEXXUS_SUPERVISOR_GERAL");
  }

  @computed
  get isPromotor(){
    return this.isLogged && this.loginData.tipo === "NEXXUS_PROMOTOR";
  }

  @computed
  get isVendedorOrOperador() {
    return this.isVendedor || this.isOperador;
  }

  @computed
  get isOperadorAdmin() {
    return this.isOperador && "administrador" in this.loginData && this.loginData.administrador === "SIM";
  }

  @computed
  get isB2B() {
    return (
      (this.isCliente && this.clienteData.unidade_negocio !== "REVENDA") ||
      (this.isVendedor && this.vendedorData.unidade_negocio !== "REVENDA")
    );
  }

  @computed
  get isNexxusPro() {
    return this.isLogged && ((!!this.loginData && this.loginData.tipo === "VENDEDOR" && !!this.vendedorData && this.vendedorData.plataforma_vendedor === "NEXXUS PRO") || (this.loginData.tipo === "OPERADOR_TELEVENDAS") || (this.loginData.tipo === "NEXXUS_DIRETOR"));
  }

  @computed
  get habilitaPedido() {
    return (
     (this.isVendedor && this.vendedorData.liberado_pedido !== "NAO") ||
      (this.isOperador && this.operadorData.liberado_pedido !== "NAO") || this.isPromotor
    );
  }  

  @computed
  get isTipoVendedor() {
    return this.isVendedor && this.vendedorData.tipo === "VENDEDOR";
  }

  @computed
  get isGerente(){
    return this.diretorData?.tipo === "GERENTE"
  }

  @computed
  get isDiretorTipo(){
    return this.isLogged && this.loginData.tipo === "NEXXUS_DIRETOR" && this.diretorData?.tipo === "DIRETOR";
  }

  @computed
  get isTipoVendaInterna() {
    return this.isVendedor && this.vendedorData.tipo === "VENDA_INTERNA";
  }

  @computed
  get vendedoresRelacionados() {
    if (this.isTipoVendaInterna && "relacionados" in this.vendedorData && !!this.vendedorData.relacionados) {
      return this.vendedorData.relacionados;
    }

    return {};
  }

  @computed
  get tipoDiretor() {
    return this.isDiretor && "tipo" in this.diretorData ? `${this.diretorData.tipo}`.toUpperCase() : null;
  }

  @computed
  get Diretor_unidade_negocio() {
    return this.isDiretor && "unidade_negocio" in this.diretorData ? `${this.diretorData.unidade_negocio}`.toUpperCase() : null;
  }

  @computed
  get loginName() {
    if (this.isLogged) {
      if (this.loginData.tipo === "NEXXUS_PROMOTOR") {
        return this.promotorData?.login;
      }
      if (this.loginData.tipo === "VENDEDOR" || this.loginData.tipo === "VENDA_INTERNA") {
        return this.vendedorData.razao_social;
      }
      if (this.loginData.tipo === "OPERADOR_TELEVENDAS") {
        return this.operadorData.razao_social;
      }
      if (this.loginData.tipo === "NEXXUS_SUPERVISOR_TINTOMETRICO" || this.loginData.tipo === "NEXXUS_SUPERVISOR_GERAL") {
        return this.supervisorData.razao_social;
      }
      if (this.loginData.tipo === "NEXXUS_DIRETOR") {
        return this.diretorData.nome || this.diretorData.razao_social || "Diretor";
      }
      return this.clienteData?.razao_social;
    }

    return null;
  }

  onConnectionChange() {
    const hasChange = this.isOnline !== navigator.onLine;

    this.isOnline = navigator.onLine;
    if (hasChange) {
      // this.ping();
    }
  }

  setLoginData(data) {
    if (!!data && "token" in data && !!data.token && this.loginToken !== data.token) {
      const { token, cliente = null, vendedor = null, operador = null, diretor = null, supervisor = null, promotor = null, ...loginData } = data;

      this.loginToken = token;
      this.loginData = loginData;

      setAuthToken(this.loginToken);

      if (this.loginData.tipo === "CLIENTE" && !!cliente) {
        this.clienteData = cliente;
      } else if (this.loginData.tipo === "NEXXUS_DIRETOR" && !!diretor) {
        this.diretorData = diretor;
      } else if ((this.loginData.tipo === "NEXXUS_SUPERVISOR_TINTOMETRICO" || this.loginData.tipo === "NEXXUS_SUPERVISOR_GERAL") && !!supervisor) {
        this.supervisorData = supervisor;
      } else if (this.loginData.tipo === "OPERADOR_TELEVENDAS" && !!operador) {
        this.operadorData = operador;
      } else if (this.loginData.tipo === "NEXXUS_PROMOTOR" && !!promotor) {
        this.promotorData = promotor;
      } else if (!!vendedor) {
        this.vendedorData = vendedor;
      }

      setTimeout(() => {
        if (this.isTipoVendaInterna && !this.relacionadoData) {
          const k = Object.keys(this.vendedoresRelacionados);
          this.setRelacionadoData(k[0]);
        }
      }, 200);

      window.gtag("config", window._ga, {
        user_id: toJS(this.loginData.login),
      });
      window.gtag("config", window._ga, {
        custom_map: {
          dimension1: "user_type",
          dimension2: "app_version",
        },
      });
      window.gtag("event", "user_type_dimension", {
        user_type: toJS(this.loginData.tipo),
        app_version: APP_VERSION,
      });
    }
  }

  @action
  setRelacionadoData(codigo) {
    if (this.isTipoVendaInterna && !!this.vendedoresRelacionados[codigo]) {
      this.relacionadoData = { codigo, razao_social: this.vendedoresRelacionados[codigo] };
    }
  }

  testLoginOriginal(n) {
    return this.isLogged && "login_original" in this.loginData && this.loginData.login_original === n;
  }

  requestPrintForm(uri) {
    this.printFormAction = getApiUrl(uri);

    const printForm = document.getElementById("printForm");
    if (!!printForm) setTimeout(() => printForm.submit(), 220);
  }

  @action
  toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  @action
  async ping() {
    if (this.isLogged && this.isOnline) {
      try {
        const { data: response } = await fetchPing(!!this.firstPing, this.location);
        if (!!response.success) {
          if ("cliente" in response.data) {
            this.clienteData = response.data.cliente;
          } else if ("diretor" in response.data) {
            this.diretorData = response.data.diretor;
          } else if ("operador" in response.data) {
            this.operadorData = response.data.operador;
          } else if ("promotor" in response.data) {
            this.promotorData = response.data.promotor;
          } else if ("vendedor" in response.data) {
            this.vendedorData = response.data.vendedor;
          }
          if ("message" in response.data && !!response.data.message) {
            this.warningMessage = response.data.message;
          }
          this.firstPing = false;
        } else {
          this.logout();
        }
      } catch (err) {
        this.logout();
      }
    }
  }

  @action
  trackTab(route, tab = null) {
    this.trackedTabs[route] = tab;
  }

  selectedTab(route, defaultTab) {
    if (!!this.trackedTabs[route]) {
      const currentTab = `${this.trackedTabs[route]}`;

      // release...
      this.trackedTabs[route] = null;

      return currentTab;
    }

    return defaultTab;
  }

  resetTabs() {
    this.trackedTabs = {};
  }

  @action
  logout() {
    this.loginToken = null;
    this.loginData = null;
    this.clienteData = null;
    this.diretorData = null;
    this.vendedorData = null;
    this.operadorData = null;
    this.promotorData = null;
    this.modalCampJun = true;
    this.firstPing = true;

    setAuthToken(null);
  }
}

export default new UIStore();
